
.App {
  background-color: #FCFAF7;
  font-family: 'Roboto';
  font-style: normal;
}

.App-header {
  padding-top: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}

label {
  font-weight: bold;
  padding-left: 10px;
}

button {
  z-index: 2;
  cursor: pointer;
  padding: 10px 0;
  border: 0px;
  color: white;
}
.crop-btn{
  padding: 5px;
}
.crop-cancel-btn{
  padding: 5px;
}

.cropped-image {
  height: auto;
  width: 100%;
}

.cropped-image-container {
  display: flex;
  flex-direction: column;
}

._coverImage-holder  {
  padding: 10px 20px;
  background-color:#959595;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}


.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.crop-container {
  height: 200px;
}

.controls {
  /* display: flex; */
  /* flex-direction: column; */
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
}
.image-border{
  border-width: 2px;
  border-color: black;
}

/* From here Client Top page style */

.is-reverse{
  flex-direction: column;
}
.is-reverse-row{
  flex-direction: row;
}
.alert-bg{
  background-color: #FF594F;
}
.alert-text{
  color: #FF594F;
}
.header-bg{
  background-color: #FFD658;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
}

.header-height{
  height: 500px;
}
.header-color{
  color:#313F4D;
}
.header-image{

  content: url("/src/assets/image/Vector-pc.png");
}
.image-full{
  width: 100%;
}
.lunchmenu-image{
  /* border: 1px solid #bb443d; */
  border-radius: 5px;
}
.header-button-padding{
  padding-bottom: 80px;
}
.header-button{
  background-color: #313F4D;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-align: center;
  padding: 20px 30px;
  border: 0px;
  border-radius: 5px;
  width: 100%;
  transition: all 0.3s ease;
}
.header-button:active{
  box-shadow: 3px 3px 10px grey;
  transform: scale(1.1);
}
.header-font{
  font-size: 20px;
  color: #313F4D;
  font-family: Roboto;
  /* font-size: 20px; */
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3em;
  
}
.lunch-title-font{
  font-size: 24px;
}
.lunch-price-font{
  font-size: 20px;
  font-weight: bolder;
}
.order-button{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  border-radius: 120px;
  background-color: #FF594F;
  color: white;
  
  transition: all 0.3s ease;
}
.order-button:active{
  box-shadow: 3px 3px 10px grey;
}
.button-disable{
  background-color: #D9D9D9;
}
.alert-text-size{
  font-size: 16px;
}
.lunch-box{
  padding-left: 3%;
  padding-right: 3%;
}
.lunch{
  background: #FFFFFF;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.04);
border-radius: 10px;
padding: 5%;
}
.for-Top{
  top: -100px;
  width: 100%;
}



.view-all-button{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  background-color: #FF594F;
  border: 0px;
  border-radius: 6px;
  padding: 25px 40px;
  transition: all 0.3s ease;
}
.view-all-button:active{
  box-shadow: 3px 3px 10px grey;
  transform: scale(1.1);
}

.footer{
  
  padding: 20px;
  text-align: center;
  color: white;
  background-color: #313F4D;
  
}


@media screen and (max-width:768px)  {
  .is-reverse{
    flex-direction: column-reverse;
  }
  .header-bg{
    background-color: #FFD658;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-image{
    
    content: url("/src/assets/image/Vector-sp.png");
  }
  .for-Top{
    top: 0;
  }
  .is-reverse-row{
    flex-direction: column-reverse;
  }
  
.lunch-title-font{
  font-size: 16px;
}
.lunch-price-font{
  font-size: 14px;
  font-weight: bolder;
}
  .order-button{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    text-align: right;
    border-radius: 120px;
    background-color: #FF594F;
    color: white;
  }
}

/* From here Client under page's style */
.under-header{
  height: 60px;
  background-color: #FFD658;
  text-align: center;
}
.under-logo{
  height: 40px;
  top: 10px;
  left: 10px;
  bottom: 10px;
}
.under-vector{
  width: 250px;
}
.lunch-detail{
  background: #FFFFFF;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin: 5vw;
  padding: 4vw;
}
.lunch-detail-title{
    font-size: 36px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
    height: 120px;
    margin: 50px 0;

}
.lunch-price-title{
  height: 120px;
  margin: 50px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-align: right;

  color: #313F4D;
}
.lunch-detail-info{
  font-size: 24px;
  letter-spacing: 0.12em;
  font-weight: lighter;
  text-align: left;

}
.pass .confirm-button{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  padding: 15px;
  color: white;
  background-color: #FF594F;
  border-radius: 120px;
  border: none;
}
.pass .confirm-button:active{
  background-color: #fa362c !important;
}
.enable .lunch-detail-order-button{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  padding: 15px;
  color: white;
  background-color: #FF594F;
  border-radius: 120px;
  border: none;
}
.disable .lunch-detail-order-button{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  padding: 15px;
  color: white;
  background-color: lightgrey;
  border-radius: 120px;
  border: none;
}
.enable .lunch-detail-order-button:active{
  background-color: #fa362c !important;
}
.back-button{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #161d23;
  
  padding: 15px;
  border-radius: 120px;
}
@media screen and (max-width:768px)  {
  .lunch-price-title{
    height: 0px;
    margin: 50px 0;
  }
  .lunch-detail-info{
    margin-top: 30px;
  }
  .lunch-detail-order-button.disable{
    font-size: 15px;
  }
}

/* from here confirm page style */
.confirm .title{
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  padding-bottom: 40px;
  color: #313F4D;
}
.confirm .detail{
  background: #F5F5F5;
  border-radius: 10px;
  padding: 5vw;
}
.confirm .detail-label{
  font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    /* or 267% */


    color: #313F4D;
}
.confirm .detail-info{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 48px;
  /* or 267% */

  letter-spacing: 0.06em;

  color: #313F4D;
}
.confirm .remind{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  padding: 40px;

  color: #313F4D;
}

.client-alert-back{
  z-index: 10;
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}
.client-alert{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  max-height: 90%;
  overflow-y: auto;
  transform: translate(-50%,-50%);
  padding: 10px;
  background-color: white;
  box-shadow: 4px 4px 20px grey;
  text-align: center;
  
}
.alert-title{
  font-size: 20px;
  font-weight: bolder;
  margin: 5%;
}
.alert-body{
  margin: 2%;
  background-color: #F5F5F5;
  padding: 2%;
  border-radius: 5px;
  
}
.footer-up{
  margin-bottom: 150px;
}
.qr-wrap{
  /* overflow-wrap: normal; */
  width: 50%;
  margin: 20px;
}
@media screen and (max-width:1024px)  {
  #qrcode{
    display: none;
  }
}
.qr-ex{
  /* width: 30%; */
  padding: 15px;
  font-weight: bolder;
  font-size: 25px;
}
.qr-title{
  padding-top: 20px;
  font-size: 30px;
  border-bottom: 5px solid #FFD658;
  margin-bottom: 20px;
}
.qrcode{
  /* width: 70%; */
}


  @media screen and (max-width:768px)  {
    .lunch-price-title{
      height: 0px;
      margin: 50px 0;
    }
    .lunch-detail-info{
      margin-top: 30px;
    }
    .client-alert{
      width: 90%;
    }
  }