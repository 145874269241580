.login-page{
    height: 100vh;
    width: 100vw;
}
.login-right{
    background: #313F4D;
    height: 100%;
}
.login .login-btn{
    background-color: #313F4D;
    border: none;
    
}
.login .login-btn:hover{
    background-color: #1a2129 !important;
    
}
.admin-header{
    background-color: #313F4D;
    width: 100%;
    padding: 20px 40px;
}
.avatar-img{
    width: 50px;
}
.admin-footer{
    background-color: #595e63;
    width: 100%;
    padding: 20px 40px;
}
.admin-nav{
    border-radius: 10px;
    overflow: hidden;
}
.admin-navbtn{
    text-align: center;
    font-size: 24px;
    background-color: #959595;
    color: white;
    padding: 14px;
    transition: all ease-in-out 0.3s;
}
.admin-navbtn:hover{
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    background-color: #69697e;
    color: white;
    padding: 14px;
}
.content-bg{
    margin-top: 20px;
    padding: 40px;
    background-color: #E9ECEE;
    border-radius: 10px;
}
.left-border-title{
    padding-left: 15px;
    border-left: 5px solid #313F4D;
    font-size: 20px;
    font-weight: bolder;
}
.add-sth-btn{
    padding: 7px;
    background-color: #FF594F;
    color: white;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 5px;
}
.super-btn{
    padding: 7px;
    background-color: #80a3b5;
    color: white;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 5px;
}
.white-box{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 20px;
    /* overflow-y: scroll; */
}
.set-height{
    height: 70vh;
}
.del-btn{
    width:30%;
    font-size: 14px;
    padding: 4px;
    background-color: #959595;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}
.edit-btn{
    width:30%;
    font-size: 14px;
    padding: 4px;
    background-color: #313F4D;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}
.switch-title{
    font-size: 22px;
    font-weight: bolder;
    color: #313F4D;
    padding: 10px;
    margin: 0 20px;
    border-bottom: 4px solid transparent;
    transition: all 0.2s ease;
}
.switch-title:hover{
    color: #313F4D;
    border-bottom: 4px solid #9b9fa3;
}
.switch-border{
    border-bottom: 4px solid #313F4D;
}
.attach-btn{
    position:absolute;
    top: 40px;

}
.admin-lunchbox{
    margin: 10px 0;
    border-radius: 10px;
    padding: 15px;
    background-color: white;
    box-shadow: 3px 3px 10px grey;
}
.admin-lunchtitle{
    font-size: 18px;
}
.admin-lunchprice{
    
}
.admin-lunchimage{
    width: 100%;
    border: 1px solid #69697e;
    border-radius: 10px;
    padding: 3px;
    margin-bottom: 10px;
}
.lunch-del-btn{
    font-size: 18px;
    padding: 7px;
    width: 30%;
    background-color: #959595;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}
.lunch-edit-btn{
    font-size: 18px;
    width: 50%;
    padding: 7px;
    background-color: #313F4D;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: none;
}
.add .lunch-edit-btn{
    font-size: 18px;
    width: 50%;
    padding: 7px;
    background-color: #313F4D;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: none;
}
.add .lunch-edit-btn:hover{
    background-color: #141a1f !important;
}
.admin-modal-back{
    z-index: 10;
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
  }
  .admin-modal{
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translate(-50%,-50%);
    padding: 10px;
    background-color: white;
    box-shadow: 4px 4px 20px grey;
    text-align: center;
  
  }
  .cancel-btn{
    background-color: transparent;
    color: #313F4D;
    font-size: 14px;
    font-weight: bold;
  }
  .table.table-bordered th{
    font-weight: bolder;
  }
  .month-picker{
    position: absolute;
    top: 3%;
    left: 5px;
    /* width: 150px; */
    height: 40px;
    border-radius: 7px;
    border: none;
    color: #1976D2;
    padding: 10px;
    font-size: 20px;
  }
  input[type="month"]::-webkit-calendar-picker-indicator {
    color: #1976D2;
    opacity: 1;
    display: block;
    background: url("./assets/image/admin/v-icon.svg") no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translateY(-50%);
}


.name-box{
    position: relative;
    width: 100%;
    height: 40vh;
    background: #F3F7FA;
    border-radius: 4px;
    overflow-y: scroll;
}
.plus-name{
    position: absolute;
    top: 0;
    right: -30px;
}
.name-modal{
    width: 30%;
}
.name-tag{
    background-color: white;
    border-radius: 5px;
    position: relative;
    padding: 7px 15px 7px 7px;
    font-size: 15px;
    margin: 4px;
    box-shadow: 3px 3px 4px lightgrey;
}
.del-icon{
    position: absolute;
    top: 2px;
    right: 2px;
}

.center-border{
    border-right: 1px solid grey;
}
.usericon{
    cursor: pointer;
}

.image-upload-button{
    /* font-size: ; */
    background-color: #959595;
    color: white;
}

.download-csv{
 position: absolute;
 top: 3%;
 right: 5%;
 width: auto;   
}
.show-orderdetail{
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #c8c7c7;
    border-radius: 5px;
    background-color: #eee;
    height: 150px;
}
.order-text{
    font-size: 15px;
    padding: 0px;
    margin: 0 0 5px 0;
}




/* image upload */

.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    color: #fff;
    font-family: "Montserrat";
    background-color: #a6a8aa;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .upload p {
    letter-spacing: 0.1em;
    font-size: 16px;
    text-transform: uppercase;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }
  .upload.is-highlight {
    background-color: rgba(74, 160, 234, 0.5);
  }
  .upload.is-highlight p {
    
  }
  .upload.is-drop p {
    display: none;
  }
  
  .upload-button {
    width: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .upload-file {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .pointer{
    cursor: pointer;
  }