.load-container {
  z-index: 100;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    align-content: center;
  }
  
  .load-flex {
    min-height: 60pt;
  }
  
  @-webkit-keyframes loading {
    0% {
      width: 50pt;
      height: 50pt;
      margin-top: 0;
    }
    25% {
      height: 4pt;
      margin-top: 23pt;
    }
    50% {
      width: 4pt;
    }
    75% {
      width: 50pt;
    }
    100% {
      width: 50pt;
      height: 50pt;
      margin-top: 0;
    }
  }
  
  @keyframes loading {
    0% {
      width: 50pt;
      height: 50pt;
      margin-top: 0;
    }
    25% {
      height: 4pt;
      margin-top: 23pt;
    }
    50% {
      width: 4pt;
    }
    75% {
      width: 50pt;
    }
    100% {
      width: 50pt;
      height: 50pt;
      margin-top: 0;
    }
  }
  .load-loader {
    width: 50pt;
    height: 50pt;
    border-radius: 100%;
    border: #313F4D 4pt solid;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    -webkit-animation: loading 1s infinite;
            animation: loading 1s infinite;
  }
  
  .load-load-text {
    padding-top: 15px;
    text-align: center;
    font: 14pt "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: 313F4D;
  }